import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/blog/banner'
import Blog from '../containers/blog/blog-area'

export default () => (
    <Layout>
        <SEO 
            title="Статьи для развития вашего бизнеса. Заходите к нам!"
            description={
                `Блог с полезными маркетинговыми статьями. 
                Многолетний опыт наших специалистов в подробных лайфхаках!`
            }
        />
        <Banner/>
        <Blog/>
    </Layout>
)
